<template>
  <div class="gstc-wrapper" ref="gstc"></div>
</template>

<script>
import GSTC from 'gantt-schedule-timeline-calendar';
import {Plugin as TimelinePointer} from 'gantt-schedule-timeline-calendar/dist/plugins/timeline-pointer.esm.min.js';
import {Plugin as Selection} from 'gantt-schedule-timeline-calendar/dist/plugins/selection.esm.min.js';
import {Plugin as ItemResizing} from 'gantt-schedule-timeline-calendar/dist/plugins/item-resizing.esm.min.js';
import {Plugin as ItemMovement} from 'gantt-schedule-timeline-calendar/dist/plugins/item-movement.esm.min.js';
import 'gantt-schedule-timeline-calendar/dist/style.css';

let gstc, state;

const columns = {
  data: {
    [GSTC.api.GSTCID('label')]: {
      id: GSTC.api.GSTCID('label'),
      data: 'label',
      sortable: false,
      expander: true,
      isHTML: false,
      width: 180,
      header: {
        content: 'Table',
      },
    },
    [GSTC.api.GSTCID('progress')]: {
      id: GSTC.api.GSTCID('progress'),
      data({row, vido}) {
        return vido.html`<div style="text-align:center">${row.progress}</div>`;
      },
      width: 120,
      sortable: 'progress',
      header: {
        content: 'Reservations',
      },
    },
  },
};

const hours = [
  {
    zoomTo: 100, // we want to display this format for all zoom levels until 100
    period: 'hour',
    periodIncrement: 1,
    format({timeStart}) {
      return timeStart.format('HH:mm DD MMMM YYYY'); // full list of formats: https://day.js.org/docs/en/display/format
    },
  },
];

const minutes = [
  {
    zoomTo: 100, // we want to display this format for all zoom levels until 100
    period: 'minute',
    periodIncrement: 15,
    main: true,
    format({timeStart, vido}) {
      return vido.html`<div style="text-align:center;">${timeStart.format('HH:mm')}</div>`; // full list of formats: https://day.js.org/docs/en/display/format
    },
  },
];

function itemSlot(vido, props) {
  const {html, onChange, update} = vido;

  let imageSrc = '';
  let description = '';
  onChange((newProps) => {
    props = newProps;
    if (!props) return;
    imageSrc = props.item.img;
    description = props.item.description;
    update();
  });

  return (content) =>
      html`
        <div class="item-text">
          <div class="item-label">${content}</div>
          <div class="item-description" style="font-size:11px;margin-top:2px;color:#fffffff0;line-height:1em;">
            ${description} person
          </div>
        </div>`;
}


export default {
  name: "VueGannt",
  props: {
    rows: Array,
    items: Array
  },

  computed: {
    customItems() {
      return () => {
        const items = {};
        if (this.items && this.items.length > 0)
          this.items.forEach((item, i) => {
            let start = GSTC.api.date(item.start);
            let end = GSTC.api.date(item.end);
            const id = GSTC.api.GSTCID(i.toString());
            const rowId = GSTC.api.GSTCID(item.table_id);
            items[id] = {
              id,
              label: item.label,
              rowId,
              description: item.persons,
              time: {
                start: start.valueOf(),
                end: end.valueOf()
              }
            }
          });
        return items;
      }
    },
    customRows() {
      const rows = {};
      if (this.rows && this.rows.length > 0)
        this.rows.forEach((elt) => {
          const id = GSTC.api.GSTCID(elt.uuid);
          rows[id] = {
            label: `${elt.label}`,
            progress: this.items.filter((item) => item.table_id == elt.uuid).length
          }
        })
      return rows;
    }
  },
  mounted() {
    const self = this;
    this.customItems();
    /**
     * @type { import("gantt-schedule-timeline-calendar").Config }
     */
    const config = {
      licenseKey: '====BEGIN LICENSE KEY====\\nlPSKf0yQHuw3VeDqp8oz/JD6TgYhO1DXChq3/yOs2H0Wo2xsQVaJiMzg9kc6kbgtMUQdmofFTd/ERgP3NpkK7hom332iUsvjNp+4uXBMrZDlz9dDt1swstbP6vDkyecyPgTypmsPnkPUxNEGMrK001uFT0ze927giPswdxsZugayyij0CI+N3LfttvFtgdKY2x/ylGBwnMv2AVNQb11hswJWjfoEKVaQNLNLK+WjeNlqyjiV/Cnja+/aDWPAkmRUyF3RZQSh9+qVALlagfURPD/pMpaF1M+RmOn1UnmIHcYP55Indmx9i1+8e3eIxmEJSvOjicmJZBvjDKt9b493QQ==||U2FsdGVkX19X9baBfelEOq6irJjkrkgIrjneb2MeDQJmILMR4PIFTIlCju+5v1PktMfAOgIXH1LArVuP2ixq7UjERiXXAoxaIa8eu4nrYOI=\\natRT8HJn/J1MWkc95t5IlBx7pN68ZtBFwcXI5k2imYkrVDFyPO+sUYXAxghnVmpP2j0zb7aQzDpH7TZPcJ96EGXDrJb2xH0+BmCFjUi25RAxxD4mPY4tXwgZefqI/Mrvx3W/Xx3a8r/WB1EOrpIPZKAmVhOrkoDo7pjGc6A2IYVccPqrDvj+PXRliBh/KVkPc0cOIvEvUoG565UtRU2YYDvP8AQ5WdOG2ArIE3Derr+TCyq4JgL5XMinSgQVpoKn1jkyOhcjeACeUgKVoLuEWuMK5gWJkWw1tJXMU2eT3wHT8efyewmlInA3/iFJDlNfbJHq08BJf7P636C++UW4wA==\\n====END LICENSE KEY====',
      plugins: [TimelinePointer(), /*Selection(), ItemResizing(), ItemMovement()*/],
      list: {
        columns: columns,
        rows: self.customRows
      },
      chart: {
        items: self.customItems(),
        calendarLevels: [hours, minutes],
        time: {
          zoom: 14
          /*          zoomTo: 22,
                    period: 'day',
                    main: true,
                    periodIncrement: 1,
                    classNames: ['gstc-date-vertical'],
                    format({ timeStart, className, vido }) {
                      return vido.html`<div class="${className}-content gstc-date-top">${timeStart.format(
                          'DD'
                      )}</div><div class="${className}-content gstc-date-extra-small">${timeStart.format(
                          'ddd'
                      )}</div>`;
                    },
                    from: 0,
                    to: 0,
                    zoom: 16,
                    leftGlobal: 0,
                    rightGlobal: 0,
                    calculatedZoomMode: false,
                    onLevelDates: [],
                    onCurrentViewLevelDates: [],
                    onDate: [],
                    allDates: [],*/
        }
      },
      slots: {
        'chart-timeline-items-row-item': {content: [itemSlot]},
      },
    };
    state = GSTC.api.stateFromConfig(config);
    gstc = GSTC({
      element: this.$refs.gstc,
      state,
    });
  },
  beforeDestroy() {
    if (gstc) gstc.destroy();
  },
  beforeUnmount() {
    if (gstc) gstc.destroy();
  },
  methods: {
    updateFirstRow() {
      state.update(`config.list.rows.${GSTC.api.GSTCID('0')}`, row => {
        row.label = 'Changed dynamically';
        return row;
      });
    },
    changeZoomLevel() {
      state.update('config.chart.time.zoom', 21);
    }
  }
}
</script>

<style scoped>
.gstc-component {
  margin: 0;
  padding: 0;
}

.toolbox {
  padding: 10px;
}
</style>

<style scoped>

</style>
