<template>
  <v-container class="pa-0 px-0" fluid>
    <v-progress-linear indeterminate v-if="this.loading"/>
    <vue-gannt v-else v-bind:rows="this.rows" v-bind:items="this.items"/>
  </v-container>
</template>

<script>
/*import {GGanttChart, GGanttRow} from 'vue-ganttastic'*/
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {mapState} from "vuex";
import VueGannt from '@/components/common/VueGannt'

export default {
  name: "ReservationGannt",
  components: {
    /*   GGanttChart,
       GGanttRow*/
    VueGannt
  },
  computed: {
    ...mapState([
      'app'
    ]),

  },
  data: () => {
    return {
      start: "2021-01-13 00:00",
      end: "2021-03-30 00:00",
      reservations: [],
      loading: false,
      rows: null,
      items: null
    }
  },

  methods: {
    loadReservations() {
      this.loading = true;
      this.axios.post(ENDPOINTS.RESERVATION.RESERVATIONS.RESERVATIONBYROOM + '?gannt=').then((res) => {

        if (res.data.STATUS === "SUCCESS") {
          /*this.start = res.data.data.start;
          this.end = res.data.data.end;*/
          this.rows = res.data.data.rows;
          this.items = res.data.data.bars;
          //this.$forceUpdate();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  mounted() {
    this.loadReservations();
  }
}
</script>


<style>
#g-gantt-chart {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: visible !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 23px;
}

#g-gantt-bar {
  min-width: 100px !important;
}

#g-gantt-chart >>> * {
  font-family: Roboto, Verdana;
}

#g-gantt-rows-container {
  position: relative;
  overflow-x: visible !important;
}

.g-gantt-bar, .g-timeaxis-hour {
  min-width: 150px !important;
}

#g-timeaxis, .g-timeaxis-days, .g-timeaxis-day, .g-timeaxis-day > div {
  display: flex;
  overflow: hidden;
}

.g-gantt-row > .g-gantt-row-bars-container {
  position: relative;
  border-top: 1px solid #eaeaea;
  width: 70%;
  border-bottom: 1px solid #eaeaea;
  overflow-x: visible !important;
}

</style>
