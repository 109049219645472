<template>
  <v-container class="pa-0 ma-0 px-0 mx-0" fluid>
    <v-row>
      <v-col class="pb-0 mb-0" cols="12">
        <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card mb-3">
          <div class="no-gutters row">
            <v-progress-linear v-if="this.loadData" class="pa-0 ma-0" indeterminate/>
            <div class="col-md-12 col-lg-4">
              <ul class="list-group list-group-flush">
                <li class="bg-transparent list-group-item">
                  <div class="widget-content p-0">
                    <div class="widget-content-outer">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-numbers  pr-3">
                            <font-awesome-icon :icon="['fal', 'calendar-check']" class="text-success " size="2x"
                                               style="font-size: 40px"/>
                          </div>
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading">{{ $t('generic.lang_confirmed') }}</div>
                          <div class="widget-subheading">{{ $t('generic.lang_onlyForToday') }}</div>
                        </div>
                        <div class="widget-content-right">
                          <div class="widget-numbers text-success">
                            <countTo :duration='3000' :endVal='this.statistics.confirmed ? parseInt(this.statistics.confirmed) : 0'
                                     :startVal='0'></countTo>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="bg-transparent list-group-item">
                  <div class="widget-content p-0">
                    <div class="widget-content-outer">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-numbers  pr-3">
                            <font-awesome-icon :icon="['fal', 'globe']" class="text-info " size="2x"
                                               style="font-size: 40px"/>
                          </div>
                        </div>

                        <div class="widget-content-left">
                          <div class="widget-heading">{{ $t('generic.lang_online') }}</div>
                          <div class="widget-subheading">{{ $t('generic.lang_onlyForToday') }}</div>
                        </div>
                        <div class="widget-content-right">
                          <div class="widget-numbers text-info">
                            <countTo :duration='3000' :endVal='this.statistics.online ? parseInt(this.statistics.online) : 0'
                                     :startVal='0'></countTo>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-12 col-lg-4">
              <ul class="list-group list-group-flush">
                <li class="bg-transparent list-group-item">
                  <div class="widget-content p-0">
                    <div class="widget-content-outer">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-numbers  pr-3">
                            <font-awesome-icon :icon="['fal', 'user-times']" class="text-danger " size="2x"
                                               style="font-size: 40px"/>
                          </div>
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading">{{ $t('generic.lang_canceled') }}</div>
                          <div class="widget-subheading">{{ $t('generic.lang_onlyForToday') }}</div>
                        </div>
                        <div class="widget-content-right">
                          <div class="widget-numbers text-danger">
                            <countTo :duration='3000' :endVal='this.statistics.canceled ? parseInt(this.statistics.canceled) : 0'
                                     :startVal='0'></countTo>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="bg-transparent list-group-item">
                  <div class="widget-content p-0">
                    <div class="widget-content-outer">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-numbers  pr-3">
                            <font-awesome-icon :icon="['fal', 'phone']" class="text-warning " size="2x"
                                               style="font-size: 40px; color: purple !important"/>
                          </div>
                        </div>

                        <div class="widget-content-left">
                          <div class="widget-heading">{{ $t('generic.lang_viaPhone') }}</div>
                          <div class="widget-subheading">{{ $t('generic.lang_onlyForToday') }}</div>
                        </div>
                        <div class="widget-content-right">
                          <div class="widget-numbers" style="color: purple !important">
                            <countTo :duration='3000' :endVal='this.statistics.phone ? parseInt(this.statistics.phone) : 0'
                                     :startVal='0'></countTo>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-12 col-lg-4">
              <ul class="list-group list-group-flush">
                <li class="bg-transparent list-group-item">
                  <div class="widget-content p-0">
                    <div class="widget-content-outer">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-numbers  pr-3">
                            <font-awesome-icon :icon="['fal', 'users']" class="text-secondary" size="2x"
                                               style="font-size: 40px"/>
                          </div>
                        </div>

                        <div class="widget-content-left">
                          <div class="widget-heading">{{ this.$t('customers.lang_customers') }}</div>
                          <div class="widget-subheading">{{ $t('generic.lang_lastYearExpenses') }}</div>
                        </div>
                        <div class="widget-content-right">
                          <div class="widget-numbers text-secondary">
                            <countTo :duration='3000' :endVal='this.statistics.customers ? parseInt(this.statistics.customers) : 0'
                                     :startVal='0'></countTo>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="bg-transparent list-group-item pa-0">
                  <v-progress-linear v-if="this.loading" class="pa-0 ma-0" indeterminate/>
                  <div class="widget-content pt-3">

                    <div class="widget-content-outer pa-0 text-center" style="cursor: pointer" @click="switch_status">

                      <font-awesome-icon :icon="['fal', 'power-off']" class="mx-auto"
                                         size="2x" style="font-size: 40px;"
                                         v-bind:class="this.reservation_enabled? 'text-success'  : 'text-danger'"/>


                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </v-col>

      <v-col class="pt-0 mt-0" cols="12">
        <div class="content mt-0 pa-0">
          <!--reservation types -->
          <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" v-if="currentTab === 'tab1'">

            <v-card class="main-card mb-3 card pa-0 ma-0 border-top" outlined>

              <div class="card-body pa-0">
                <div class="card-title  card-header">

                  <div :class="[this.$vuetify.theme.dark? 'white--text' : '']"
                       class="font-size-lg text-capitalize font-weight-normal">
                    {{ $t('generic.lang_reservation') }}
                  </div>
                  <div class="btn-actions-pane-right actions-icon-btn">

                    <div class="btn-actions-pane-right actions-icon-btn pt-2">
                      <v-btn outlined to="/pos/gastro/tables" color="success">
                        {{ this.$t("settings.lang_nav_settings_roomplaner") }}
                      </v-btn>
                      <v-btn class="elevation-0 pl-2 pr-2" color="primary" @click="handleClick('tab2')">
                        <v-icon class="ml-0 pl-0">
                          add
                        </v-icon>
                        {{ this.$t('generic.lang_create') }}
                      </v-btn>
                    </div>
                  </div>
                </div>
                <b-tabs v-model="tab" content-class="mt-3">
                  <b-tab :active="this.tab===0" @click="changeTab(0)">
                    <template v-slot:title>
                      <font-awesome-icon :icon="['fal', 'list']" class="text-muted actions" size="2x"
                                         style="font-size: 16px"/>
                      <span class="pl-2">{{ $t('generic.lang_list') }}</span>
                    </template>
                    <reservation-list @reloadStatistics="loadStatistics" v-if="this.tab===0"/>
                  </b-tab>
                  <b-tab :active="this.tab===1" :title="$t('generic.lang_calendar')" @click="changeTab(1)">
                    <template v-slot:title>
                      <font-awesome-icon :icon="['fal', 'calendar']" class="text-muted actions" size="2x"
                                         style="font-size: 16px"/>
                      <span class="pl-2">{{ $t('generic.lang_calendar') }}</span>
                    </template>
                    <reservation-calendar @reloadStatistics="loadStatistics" v-if="this.tab===1"/>
                  </b-tab>

                  <!--                  <b-tab :active="this.tab===2" title="Gannt" @click="changeTab(2)">
                                      <template v-slot:title>
                                        <font-awesome-icon :icon="['fal', 'stream']" class="text-muted actions" size="2x"
                                                           style="font-size: 16px"/>
                                        <span class="pl-2">Gannt</span>
                                      </template>
                                      <reservation-gannt v-if="this.tab===2"/>
                                    </b-tab>-->
                </b-tabs>

              </div>

            </v-card>

          </div>


          <!-- confirmation config -->
          <div v-if="currentTab === 'tab2'">

            <create-reservation @reloadStatistics="loadStatistics" v-model="currentTab" show_close/>

          </div>

        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Tabs from 'vue-tabs-with-active-line';
import Calendar from "@/views/accounting/Calendar";
import ReservationCalendar from "@/components/reservation/reservations/ReservationCalendar";
import ReservationList from "@/components/reservation/reservations/ReservationList";

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faCalendar,
  faCalendarCheck,
  faCalendarTimes,
  faGlobe,
  faList,
  faPhone,
  faPowerOff,
  faStream,
  faUserCheck,
  faUsers,
  faUserTimes
} from '@fortawesome/pro-light-svg-icons';
import CreateReservation from "@/components/reservation/reservations/CreateReservation";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

import countTo from 'vue-count-to';
import ReservationGannt from "@/components/reservation/reservations/ReservationGannt";

library.add(faCalendar, faList, faUserCheck, faUserTimes, faUsers, faGlobe, faPhone, faPowerOff, faCalendarTimes, faCalendarCheck, faStream);

export default {
  components: {
    ReservationGannt,
    countTo,
    CreateReservation,
    ReservationList,
    ReservationCalendar,
    Calendar,
    'font-awesome-icon': FontAwesomeIcon,
    Tabs
  },
  name: "ReservationComponent",
  data: () => {
    return {
      loading: false,
      reservation_enabled: false,
      currentTab: 'tab1',
      loadData: false,
      tab: 0,
      statistics: {
        canceled: 0,
        confirmed: 0,
        online: 0,
        phone: 0,
        unconfirmed: 0,
        customers: 0
      }
    }
  },
  mounted() {
    this.loadStatistics();
    this.loadConfig();
  },
  computed: {
    tabs: function () {
      return [{
        title: this.$t('generic.lang_reservation'),
        value: 'tab1',
      },
        {
          title: this.$t('generic.lang_createReservation'),
          value: 'tab2',
        }];
    }
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
    loadConfig() {
      this.loadData = true;
      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.CONFIG.GET).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.reservation_enabled = res.data.configs.reservation_enabled;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadData = false;
      })
    },
    switch_status() {
      this.loading = true;


      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.CONFIG.UPDATE, {
        configuration: {
          reservation_enabled: !this.reservation_enabled
        }
      }).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
          this.reservation_enabled = !this.reservation_enabled;
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).then((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    loadStatistics() {
      this.loadData = true;
      this.axios.post(ENDPOINTS.RESERVATION.RESERVATIONS.STATISTICS)
          .then((res) => {
            if (res.data.STATUS === "SUCCESS") {
              this.statistics = res.data.statistics;

            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadData = false;
      })
    },
    handleClick(newTab) {
      this.currentTab = newTab;
    }
  }
}
</script>

<style>
.shadow-tabs {
  z-index: 1 !important;
}
</style>
